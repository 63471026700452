// General imports
import * as React from "react";

// Clock component imports
import PathMesh from "./PathMesh";

// React component that contains all miscellaneous indicators
const MiscIndicators = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, selected = 8, mainClickHandler = () => {}, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp,
    }

    // Respond to click event
    const clickHandler = (event, meshRef) => {
        mainClickHandler(event, meshRef);
    }

    // Return canvas with components
    return (
        <group {...props}>
            {/* Original LCD design */}
            <PathMesh {...pathMeshProps} pathIds={['light']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['camera']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['music']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['phone']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['calendar']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['alarm']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['email']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['message']} segment={true} />
            <PathMesh {...pathMeshProps} pathIds={['notification']} segment={true} />

            {/* Resume LCD design */}
            <PathMesh {...pathMeshProps} pathIds={['phone_2']} segment={true} on={selected === 0} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'tel:0031620864738'} nr={20}/>
            <PathMesh {...pathMeshProps} pathIds={['email_2']} segment={true} on={selected === 1} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'mailto:info@karssiesengineering.com'} nr={21}/>
            <PathMesh {...pathMeshProps} pathIds={['whatsapp_full']} segment={true} on={selected === 2} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'https://wa.me/31620864738'} nr={22}/>
            <PathMesh {...pathMeshProps} pathIds={['github']} segment={true} on={selected === 3} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'https://github.com/hjkarssies'} nr={23}/>
            <PathMesh {...pathMeshProps} pathIds={['tu_delft']} segment={true} on={selected === 4} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'http://resolver.tudelft.nl/uuid:71257d1e-c65b-4eb7-9df0-869b9419a8c2'} nr={24}/>
            <PathMesh {...pathMeshProps} pathIds={['researchgate']} segment={true} on={selected === 5} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'https://www.researchgate.net/profile/Jan-Karssies'} nr={25}/>
            <PathMesh {...pathMeshProps} pathIds={['linkedin']} segment={true} on={selected === 6} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'https://www.linkedin.com/in/hjkarssies/'} nr={26}/>
            <PathMesh {...pathMeshProps} pathIds={['cv_2']} segment={true} on={selected === 7} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} url={'./assets/Curriculum Vitae - H.J. Karssies.pdf'} nr={27}/>
            <PathMesh {...pathMeshProps} pathIds={['info']} segment={true} on={selected === 8} clickHandler={clickHandler}
                box boxScale={[1.25, 1.25, 0.001]} interactive  nr={28}/>
        </group>
    );
}

// Make MiscIndicators the default export
export default MiscIndicators;