import './App.css';
import * as React from "react";
import ThreeCasioClock from './ThreeCasioClock/ThreeCasioClock.js';

// Main React App
function App() {
  return (
    <div className="text-center w-full h-full absolute bg-black">
      <ThreeCasioClock/>
    </div>
  );
}

export default App;
