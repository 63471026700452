// General imports
import * as THREE from 'three';
import * as React from "react";
import { OrbitControls } from '@react-three/drei'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { Shadow } from "@react-three/drei";

// Clock component imports
import PathMesh from "./PathMesh.js"
import TimeIndicator from "./TimeIndicator.js";
import TextIndicator from "./TextIndicator.js";
import ProgressIndicator from "./ProgressIndicator.js";
import BatteryIndicator from "./BatteryIndicator.js";
import MiscIndicators from "./MiscIndicators.js";

// Default parameters
const casioClockImage = "./assets/display_design_cv.svg"

// List of timezones to be used
const zoneNames = [
    {'name': 'Pago Pago', 'code': 'Pacific/Pago_Pago', 'segment': 0},
    {'name': 'Honolulu', 'code': 'Pacific/Honolulu', 'segment': 0},
    {'name': 'Anchorage', 'code': 'America/Anchorage', 'segment': 0},
    {'name': 'Vancouver', 'code': 'America/Vancouver', 'segment': 0},
    {'name': 'Los Angeles', 'code': 'America/Los_Angeles', 'segment': 0},
    {'name': 'Edmonton', 'code': 'America/Edmonton', 'segment': 1},
    {'name': 'Denver', 'code': 'America/Denver', 'segment': 1},
    {'name': 'Mexico City', 'code': 'America/Mexico_City', 'segment': 2},
    {'name': 'Chicago', 'code': 'America/Chicago', 'segment': 2},
    {'name': 'Medellin', 'code': 'America/Bogota', 'segment': 3},
    {'name': 'New York', 'code': 'America/New_York', 'segment': 3},
    {'name': 'Santiago', 'code': 'America/Santiago', 'segment': 4},
    {'name': 'Halifax', 'code': 'America/Halifax', 'segment': 4},
    {'name': 'St Johns', 'code': 'America/St_Johns', 'segment': 4},
    {'name': 'Rio de Janeiro', 'code': 'America/Sao_Paulo', 'segment': 5},
    {'name': 'Fernando de Noronha', 'code': 'America/Noronha', 'segment': 5},
    {'name': 'Praia', 'code': 'Atlantic/Cape_Verde', 'segment': 6},
    {'name': 'Lisbon', 'code': 'Europe/Lisbon', 'segment': 6},
    {'name': 'London', 'code': 'Europe/London', 'segment': 6},
    {'name': 'Dokkum', 'code': 'Europe/Amsterdam', 'segment': 7},
    {'name': 'Madrid', 'code': 'Europe/Madrid', 'segment': 7},
    {'name': 'Paris', 'code': 'Europe/Paris', 'segment': 7},
    {'name': 'Rome', 'code': 'Europe/Rome', 'segment': 7},
    {'name': 'Berlin', 'code': 'Europe/Berlin', 'segment': 7},
    {'name': 'Stockholm', 'code': 'Europe/Stockholm', 'segment': 7},
    {'name': 'Athens', 'code': 'Europe/Athens', 'segment': 8},
    {'name': 'Cairo', 'code': 'Africa/Cairo', 'segment': 8},
    {'name': 'Jerusalem', 'code': 'Asia/Jerusalem', 'segment': 8},
    {'name': 'Moscow', 'code': 'Europe/Moscow', 'segment': 9},
    {'name': 'Jeddah', 'code': 'Asia/Riyadh', 'segment': 9},
    {'name': 'Tehran', 'code': 'Asia/Tehran', 'segment': 9},
    {'name': 'Dubai', 'code': 'Asia/Dubai', 'segment': 9},
    {'name': 'Kabul', 'code': 'Asia/Kabul', 'segment': 10},
    {'name': 'Karachi', 'code': 'Asia/Karachi', 'segment': 10},
    {'name': 'Delhi', 'code': 'Asia/Kolkata', 'segment': 10},
    {'name': 'Kathmandu', 'code': 'Asia/Kathmandu', 'segment': 10},
    {'name': 'Dhaka', 'code': 'Asia/Dhaka', 'segment': 10},
    {'name': 'Yangon', 'code': 'Asia/Yangon', 'segment': 11},
    {'name': 'Bangkok', 'code': 'Asia/Bangkok', 'segment': 11},
    {'name': 'Singapore', 'code': 'Asia/Singapore', 'segment': 12},
    {'name': 'Hong Kong', 'code': 'Asia/Hong_Kong', 'segment': 12},
    {'name': 'Beijing', 'code': 'Asia/Shanghai', 'segment': 12},
    {'name': 'Taipei', 'code': 'Asia/Taipei', 'segment': 12},
    {'name': 'Seoul', 'code': 'Asia/Seoul', 'segment': 13},
    {'name': 'Tokyo', 'code': 'Asia/Tokyo', 'segment': 13},
    {'name': 'Adelaide', 'code': 'Australia/Adelaide', 'segment': 13},
    {'name': 'Guam', 'code': 'Pacific/Guam', 'segment': 14},
    {'name': 'Sydney', 'code': 'Australia/Sydney', 'segment': 14},
    {'name': 'Noumea', 'code': 'Pacific/Noumea', 'segment': 14},
    {'name': 'Wellington', 'code': 'Pacific/Auckland', 'segment': 14}
]

// Links of items
const links = ['tel:0031620864738',
    'mailto:info@karssiesengineering.com',
    'https://wa.me/31620864738',
    'https://github.com/hjkarssies',
    'http://resolver.tudelft.nl/uuid:71257d1e-c65b-4eb7-9df0-869b9419a8c2',
    'https://www.researchgate.net/profile/Jan-Karssies',
    'https://www.linkedin.com/in/hjkarssies/',
    './assets/Curriculum Vitae - H.J. Karssies.pdf']

// React element that contains the canvas of the clock
const ThreeCasioClock = ({ className = '', ...props }) => {
    return (
        <Canvas camera={{ fov: 80, position: [0, 0, 15] }} shadows >
            <ClockApp className={className} {...props} />
        </Canvas>
    )
}

// React element that contains all the contents of the clock
const ClockApp = ({ className = '', ...props }) => {

    // Use Three to get access to its state
    const state = useThree();

    // Reference to group for scale on viewport resize
    const groupRef = React.useRef();
    const lightRefDirectional = React.useRef();
    const lightRefHemisphere = React.useRef();
    const sphereRef = React.useRef();

    // Creat a React state to store SVG data in
    const [svgData, setSvgData] = React.useState();
    const [timezones, setTimezones] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState(0);
    const [selectedTimezone, setSelectedTimezone] = React.useState(-1);
    const [gmtOffset, setGmtOffset] = React.useState(new Date().getTimezoneOffset() / -60);
    const [clickEvent, setClickEvent] = React.useState();
    const [text, setText] = React.useState('Karssies Engineering');

    // Respond to click event on buttons
    const clickHandler = (event, meshRef) => {
        if (event) setClickEvent(event);
    }

    // Respond to click event
    const hoverStartHandler = (event, meshRef) => {
        if (event && event.eventObject && event.eventObject.nr) {
            const scale = 1.1;
            const x = event.eventObject.nr === 11 || event.eventObject.nr === 12 ? 1 : -1;
            const y = event.eventObject.nr === 11 || event.eventObject.nr === 13 ? 1 : -1;
            meshRef.current.material.color.set('orange');
            meshRef.current.scaleTo = {scale: new THREE.Vector3(scale, -scale, scale), lerp: 0.1};
            meshRef.current.posTo = {pos: new THREE.Vector3(x * scale * 8.180 - x * 8.180, y * 2.212 - y * scale * 2.212, 0), lerp: 0.1};
        }
    }

    // Respond to click event
    const hoverEndHandler = (event, meshRef) => {
        if (event && event.eventObject && event.eventObject.nr) {
            meshRef.current.material.color.set('#eae8cd');
            meshRef.current.scaleTo = {scale: new THREE.Vector3(1, -1, 1), lerp: 0.1};
            meshRef.current.posTo = {pos: new THREE.Vector3(0, 0, 0), lerp: 0.1};
        }
    }

    // Asynchronous HTTP GET request to get timezone data
    function httpGetAsync(url, callback)
    {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() { 
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
                callback(xmlHttp.responseText);
        }
        xmlHttp.open("GET", url, true);
        xmlHttp.send(null);
    }

    // Use Effect to load SVG data and get timezone data only on render
    React.useEffect(() => {

        // Load SVG
        const svgLoader = new SVGLoader();
        svgLoader.load(casioClockImage, data => setSvgData(data))

        // Get timezone data
        try{
            httpGetAsync("https://api.timezonedb.com/v2.1/list-time-zone?key=1KIZR4GYB66L&format=json&fields=zoneName,gmtOffset,dst", strResponse => {
                const objResponse = JSON.parse(strResponse);

                // Check if message status
                if (objResponse.status === "OK") {

                    // Match retrieved data with needed timezones
                    let results = Object.keys(zoneNames).map(key => {
                        const matchingZone = objResponse.zones.find(zone => zone.zoneName === zoneNames[key]['code']);
                        if (matchingZone) {
                            return {
                                'city': zoneNames[key]['name'],
                                'zoneName': matchingZone.zoneName,
                                'gmtOffset': matchingZone.gmtOffset / 3600,
                                'segment': zoneNames[key]['segment']
                            }
                        }
                        else {
                            console.log('Could not match timezone data for timezone ' + key);
                            return null;
                        };
                    }).filter(zone => zone)

                    // Add UTC
                    results.splice(results.findIndex(zone => zone.gmtOffset >= 0), 0, {
                        'city': 'UTC',
                        'zoneName': 'UTC',
                        'gmtOffset': 0,
                        'segment': 6
                    });

                    // // Add current location
                    // results.splice(results.findIndex(zone => zone.gmtOffset >= new Date().getTimezoneOffset() / -60), 0, {
                    //     'city': 'Current location',
                    //     'zoneName': 'Current_Location',
                    //     'gmtOffset': new Date().getTimezoneOffset() / -60,
                    //     'segment': -1
                    // });

                    // Save results
                    setTimezones(results);
                    setSelectedTimezone(results.findIndex(zone => zone.zoneName === 'Europe/Amsterdam'));
                }
            })
        }

        // If something goes wrong, use only UTC and current location
        catch (error) {
            console.log("Error: wasn't able to get time zone data", error);
            setTimezones([
                {
                    'city': 'UTC',
                    'zoneName': 'UTC',
                    'gmtOffset': 0,
                    'segment': 0
                },
                // {
                //     'city': 'Current location',
                //     'zoneName': 'Current_Location',
                //     'gmtOffset': new Date().getTimezoneOffset() / -60,
                //     'segment': -1
                // }
            ])
            setSelectedTimezone(0);
        }

        // // Shadow helper
        // const shadowHelper = new THREE.CameraHelper(lightRefDirectional.current.shadow.camera);
        // state.scene.add( shadowHelper );

        // eslint-disable-next-line
    }, [])

    // Respond to a pressed button
    React.useEffect(() => {
        if (clickEvent && clickEvent.eventObject && clickEvent.eventObject.nr) {

            // Top left: next item
            if (clickEvent.eventObject.nr === 11) {
                setSelectedItem((selectedItem + 1) % 9);
            }

            // Bottom left: select item
            else if (clickEvent.eventObject.nr === 12) {
                if (selectedItem < 8) window.open(links[selectedItem]);
            }

            // Top right: next timezone
            else if (clickEvent.eventObject.nr === 13) {
                const index = (selectedTimezone + 1) % timezones.length;
                setSelectedTimezone(index);
                if (timezones[index]) {
                    setGmtOffset(timezones[index].gmtOffset);
                    setText(timezones[index].city);
                }
            }

            // Bottom right: prev timezone
            else if (clickEvent.eventObject.nr === 14) {
                const index = (selectedTimezone + timezones.length - 1) % timezones.length;
                setSelectedTimezone(index);
                if (timezones[index]) {
                    setGmtOffset(timezones[index].gmtOffset);
                    setText(timezones[index].city);
                }
            }

            // Icon buttons: select new link
            else if (clickEvent.eventObject.nr >= 20 && clickEvent.eventObject.nr < 29) {
                setSelectedItem(clickEvent.eventObject.nr - 20);
            }
        }

        // Reset pressed button
        setClickEvent(null);
        
    // eslint-disable-next-line
    }, [clickEvent])

    // Animations
    useFrame(() => {

        // Resize watch to frame
        groupRef.current.scale.x = state.viewport.aspect < 1 ? state.viewport.aspect : 1;
        groupRef.current.scale.y = state.viewport.aspect < 1 ? state.viewport.aspect : 1;
        groupRef.current.scale.z = state.viewport.aspect < 1 ? state.viewport.aspect : 1;

        // Play intro effects
        if (svgData) {
            const lerp = 0.005;
            lightRefHemisphere.current.intensity = THREE.MathUtils.lerp(lightRefHemisphere.current.intensity, 0.6, lerp);
            lightRefDirectional.current.intensity = THREE.MathUtils.lerp(lightRefDirectional.current.intensity, 0.8, lerp);
            lightRefDirectional.current.position.x = THREE.MathUtils.lerp(lightRefDirectional.current.position.x, 12, lerp);
            lightRefDirectional.current.position.y = THREE.MathUtils.lerp(lightRefDirectional.current.position.y, 24, lerp);
            lightRefDirectional.current.position.z = THREE.MathUtils.lerp(lightRefDirectional.current.position.z, 12, lerp);
            sphereRef.current.material.color.lerp(new THREE.Color(0x282c34), 4 * lerp);
        }
    })

    // Return canvas with components
    return (
        <group ref={groupRef}>

            {/* Scene */}
            <mesh ref={sphereRef}>
                <sphereGeometry args={[40, 20, 20]}/>
                <meshPhongMaterial color={0xffffff} side={THREE.BackSide} />
            </mesh>
            <hemisphereLight ref={lightRefHemisphere} skyColor='#B1E1FF' groundColor='#B97A20' intensity={0} />
            <directionalLight ref={lightRefDirectional} color='#FFFFFF' intensity={0} position={[-12, -24, 12]} castShadow
                shadow-camera-left={-10} shadow-camera-right={10} shadow-camera-top={10} shadow-camera-bottom={-10}
                shadow-mapSize-width={2048} shadow-mapSize-height={2048} />
            <Shadow />
            <OrbitControls enablePan={false} minDistance={1} maxDistance={20} />


            {/* Watch components */}
            <PathMesh svgData={svgData} pathIds={['case']} depth_a={1} />
            <PathMesh svgData={svgData} pathIds={['case_back']} depth_b={1} />
            <PathMesh svgData={svgData} pathIds={['case_inner_edge', 'case_outer_edge']} depth={1.4} color={'orange'} />
            <PathMesh svgData={svgData} pathIds={['button_bottom_right', 'button_bottom_left',
                'button_top_right', 'button_top_left']} depth={0.7} />
            <PathMesh svgData={svgData} pathIds={['background']} depth_a={0.5} />
            <PathMesh svgData={svgData} pathIds={['lines']} depth_a={0.65} />

            {/* Display components */}
            <PathMesh svgData={svgData} pathIds={['next_item']} depth={0.01} interactive box boxScale={[1.5, 1.5, 1.5]}
                clickHandler={clickHandler} hoverStartHandler={hoverStartHandler} hoverEndHandler={hoverEndHandler} nr={11} />
            <group visible={selectedItem !== 8}>
                <PathMesh svgData={svgData} pathIds={['select_item']} opacity={0.1} depth={0.01} interactive box boxScale={[1.5, 1.5, 1.5]}
                    clickHandler={clickHandler} hoverStartHandler={hoverStartHandler} hoverEndHandler={hoverEndHandler} nr={12} />
                <PathMesh svgData={svgData} pathIds={['next_timezone']} depth={0.051} interactive box boxScale={[1.5, 1.5, 1.5]}
                    clickHandler={clickHandler} hoverStartHandler={hoverStartHandler} hoverEndHandler={hoverEndHandler} nr={13} />
                <PathMesh svgData={svgData} pathIds={['prev_timezone']} depth={0.051} interactive box boxScale={[1.5, 1.5, 1.5]}
                    clickHandler={clickHandler} hoverStartHandler={hoverStartHandler} hoverEndHandler={hoverEndHandler} nr={14} />
                <TimeIndicator svgData={svgData} offset={0.5} depth_a={0.1} timezone={timezones[selectedTimezone]} gmtOffset={gmtOffset} />
                <TextIndicator svgData={svgData} offset={0.5} depth_a={0.1} text={text}/>
                <ProgressIndicator svgData={svgData} offset={0.5} depth_a={0.1} />
                <BatteryIndicator svgData={svgData} offset={0.5} depth_a={0.1} />
                <PathMesh svgData={svgData} pathIds={['bluetooth']} offset={0.5} depth_a={0.1} segment={true} />
            </group>
            
            {/* Show this when the info button is selected */}
            <group visible={selectedItem === 8}>
                <PathMesh svgData={svgData} pathIds={['logo']} offset={0.5} depth_a={0.1} on />
                <PathMesh svgData={svgData} pathIds={['info_text']} offset={0.5} depth_a={0.1} on />
            </group>

            <MiscIndicators svgData={svgData} offset={0.5} depth_a={0.1} selected={selectedItem} mainClickHandler={clickHandler}/>
        </group>
    );
}

// Make ThreeCasioClock the default export
export default ThreeCasioClock;