// General imports
import * as React from "react";
import { useFrame } from "react-three-fiber";

// Clock component imports
import PathMesh from "./PathMesh";

// React component that contains the battery indicator
const BatteryIndicator = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp
    }

    // State containing battery lavel and charging state
    const [level, setLevel] = React.useState(100)
    const [showLevel, setShowLevel] = React.useState(100)
    const [charging, setCharging] = React.useState(false)
    const [startCharging, setStartCharging] = React.useState(new Date().getTime())

    // Read battery data and attach event listeners for changes
    try {
        navigator.getBattery().then(battery => {
            setLevel(battery.level * 100);
            setCharging(battery.charging);

            battery.addEventListener('levelchange', () => {
                setLevel(battery.level * 100);
            })

            battery.addEventListener('chargingchange', () => {
                setCharging(battery.charging);
                if (battery.charging) setStartCharging(new Date().getTime());
            })
        })
    }
    catch (error) {
        console.log("Error: wasn't able to read battery data", error);
    }

    // Update the level to be shown (depends)
    useFrame(() => {
        let time = new Date();
        let milliseconds = time.getTime() - startCharging;
        if (!charging || level === 100) setShowLevel(level);
        else if (level >= 75) setShowLevel(75 + 25 * Math.floor(milliseconds % 1000 / 500));
        else if (level >= 50) setShowLevel(50 + 25 * Math.floor(milliseconds % 1500 / 500));
        else if (level >= 25) setShowLevel(25 + 25 * Math.floor(milliseconds % 2000 / 500));
        else                  setShowLevel(     25 * Math.floor(milliseconds % 2500 / 500));
    })

    // Return canvas with components
    return (
        <group {...props}>
            <PathMesh {...pathMeshProps} pathIds={['battery_edge']} segment={true} on />
            <PathMesh {...pathMeshProps} pathIds={['battery_25']} segment={true} on={showLevel >= 12.5} />
            <PathMesh {...pathMeshProps} pathIds={['battery_50']} segment={true} on={showLevel >= 37.5} />
            <PathMesh {...pathMeshProps} pathIds={['battery_75']} segment={true} on={showLevel >= 62.5} />
            <PathMesh {...pathMeshProps} pathIds={['battery_100']} segment={true} on={showLevel >= 87.5} />
        </group>
    );
}

// Make ProgressIndicator the default export
export default BatteryIndicator;