// General imports
import * as React from "react";
import { useFrame } from "react-three-fiber";

// Clock component imports
import FourteenSegmentChar from "./FourteenSegmentChar.js";

// Default parameters
const offset_x = 1.364;
const deltaTime = 200;
const waitAtNewText = 1000;


// React component that contains the text indicator
const TextIndicator = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, text, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp
    }

    // State containing a text string
    const [textString, setTextString] = React.useState('');
    const [previousTime, setPreviousTime] = React.useState(0);

    // Update text when new
    React.useEffect(() => {
        const time = new Date();
        const currentTime = time.getTime();
        setPreviousTime(currentTime + waitAtNewText);
        setTextString(text + (text.length > 7 ? '       ' : ''));
    }, [text])

    // Update function
    useFrame(() => {            
        const time = new Date();
        const currentTime = time.getTime();       
        if (textString.length > 7 && currentTime - previousTime >= deltaTime) {
            setTextString(textString.slice(1) + textString.slice(0, 1));
            setPreviousTime(currentTime);
        }
    })

    // Return canvas with components
    return (
        <group {...props}>
            <FourteenSegmentChar {...pathMeshProps} character={textString[0]} />
            <FourteenSegmentChar {...pathMeshProps} character={textString[1]} position-x={offset_x} />
            <FourteenSegmentChar {...pathMeshProps} character={textString[2]} position-x={2 * offset_x} />
            <FourteenSegmentChar {...pathMeshProps} character={textString[3]} position-x={3 * offset_x} />
            <FourteenSegmentChar {...pathMeshProps} character={textString[4]} position-x={4 * offset_x} />
            <FourteenSegmentChar {...pathMeshProps} character={textString[5]} position-x={5 * offset_x} />
            <FourteenSegmentChar {...pathMeshProps} character={textString[6]} position-x={6 * offset_x} />
        </group>
    );
}

// Make TextIndicator the default export
export default TextIndicator;