// General imports
import * as React from "react";

// Clock component imports
import PathMesh from "./PathMesh";

// Bit character map
const bitChars = {0: 0x3F, 1: 0x06, 2: 0x5B, 3: 0x4F, 4: 0x66, 5: 0x6D, 6: 0x7D, 7: 0x07, 8: 0x7F, 9: 0x6F, space: 0x00}

// React component that contains one seven segment bitChar
const SevenSegmentChar = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, character, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp
    }

    // State containing character
    const [bitChar, setBitChar] = React.useState(bitChars['space'])

    // Update when character changes
    React.useEffect(() => {
        const key = character == null ? 'space' : character.toLowerCase().replace(' ', 'space');
        setBitChar(key in bitChars ? bitChars[key] : bitChars['space']);
    }, [character])

    // Return canvas with components
    return (
        <group {...props}>
            <PathMesh {...pathMeshProps} pathIds={['7_segment_a']} segment={true} on={bitChar & (1 << 0)} />
            <PathMesh {...pathMeshProps} pathIds={['7_segment_b']} segment={true} on={bitChar & (1 << 1)} />
            <PathMesh {...pathMeshProps} pathIds={['7_segment_c']} segment={true} on={bitChar & (1 << 2)} />
            <PathMesh {...pathMeshProps} pathIds={['7_segment_d']} segment={true} on={bitChar & (1 << 3)} />
            <PathMesh {...pathMeshProps} pathIds={['7_segment_e']} segment={true} on={bitChar & (1 << 4)} />
            <PathMesh {...pathMeshProps} pathIds={['7_segment_f']} segment={true} on={bitChar & (1 << 5)} />
            <PathMesh {...pathMeshProps} pathIds={['7_segment_g']} segment={true} on={bitChar & (1 << 6)} />
        </group>
    );
}

// Make SevenSegmentChar the default export
export default SevenSegmentChar;