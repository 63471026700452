// General imports
import * as React from "react";
import { useFrame } from "react-three-fiber";

// Clock component imports
import SevenSegmentChar from "./SevenSegmentChar.js";
import PathMesh from "./PathMesh.js";

// Default parameters
const scale = 0.7;
const offset_x_char = 1.897; // Horizontal offset per character
const offset_x_colon = 4.927 - 2 * offset_x_char; // Horizontal offset for colon
const offset_x_scaled = (1 - scale) * -5.752 + 8.931; // Horizontal offset to first small character
const offset_y_scaled = (1 - scale) * -4.721; // Vertical offset for scaled characters

// // List of maximum time differences per time zone
// const timeZones = [-8, -7, -6, -5, -4, -2.5, 0, 1, 2, 4, 6, 7, 8, 9, 20];

// React component that contains the time indicator
const TimeIndicator = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, twelve_hour = false, timezone, gmtOffset, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp
    }

    // States containing all time data
    // const [timezone, setTimezone] = React.useState(-1);
    const [hoursString, setHoursString] = React.useState('  ');
    const [minutesString, setMinutesString] = React.useState('  ');
    const [secondsString, setSecondsString] = React.useState('  ');
    const [colon, setColon] = React.useState(false);
    const [pm, setPm] = React.useState(false);

    // Create an array of all timezones
    let timezones = [];
    for (let i = 0; i < 15; i++) {
        const pathId = 'timezone_' + String.fromCharCode(97 + i);
        timezones.push(<PathMesh
            {...pathMeshProps}
            pathIds={[pathId]}
            key={pathId}
            segment={true}
            on={timezone && i === timezone['segment']} />
        )
    }

    // Update function
    useFrame(() => {
        let time = new Date();
        time.setHours(time.getUTCHours() + (timezone ? gmtOffset : -time.getTimezoneOffset() / 60));
        // setTimezone(timeZones.findIndex(element => element >= (gmtOffset != null ? gmtOffset : -time.getTimezoneOffset() / 60)));
        setHoursString(String(twelve_hour ? (time.getHours() + 11) % 12 + 1 : time.getHours()).padStart(2, '0'))
        setMinutesString(String(time.getMinutes()).padStart(2, '0'))
        setSecondsString(String(time.getSeconds()).padStart(2, '0'))
        setColon(time.getMilliseconds() < 500);
        setPm(twelve_hour && time.getHours() >= 12);
    })

    // Return canvas with components
    return (
        <group {...props}>
            <SevenSegmentChar {...pathMeshProps} character={hoursString[0]} />
            <SevenSegmentChar {...pathMeshProps} position-x={offset_x_char} character={hoursString[1]} />
            <SevenSegmentChar {...pathMeshProps} position-x={2 * offset_x_char + offset_x_colon} character={minutesString[0]} />
            <SevenSegmentChar {...pathMeshProps} position-x={3 * offset_x_char + offset_x_colon} character={minutesString[1]} />
            <SevenSegmentChar {...pathMeshProps} position-x={offset_x_scaled}
                position-y={offset_y_scaled} scale={[scale, scale, 1]} character={secondsString[0]} />
            <SevenSegmentChar {...pathMeshProps} position-x={scale * offset_x_char + offset_x_scaled}
                position-y={offset_y_scaled} scale={[scale, scale, 1]} character={secondsString[1]} />
            <PathMesh {...pathMeshProps} pathIds={['colon']} segment={true} on={colon}/>
            <PathMesh {...pathMeshProps} pathIds={['pm']} segment={true} on={pm} />
            {timezones}
        </group>
    );
}

// Make TimeIndicator the default export
export default TimeIndicator;