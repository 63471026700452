import * as THREE from 'three';
import * as React from "react";
import { useFrame } from "react-three-fiber";
import { isMobile } from 'react-device-detect';

// React element that makes mesh objects out of SVG files
const PathMesh = ({ src, svgData, pathIds = [], depth, depth_a, depth_b, offset = 0, color, lerp = 0.6, segment = false, on = false,
    url, nr = -1, interactive = false, box = false, boxScale = [1, 1, 1],
    clickHandler = () => {}, hoverStartHandler = () => {}, hoverEndHandler = () => {}, ...props }) => {

    // Hover state and state containing meshes
    const [hover, setHover] = React.useState(false);
    const [meshList, setMeshList] = React.useState([]);

    // Reference used for changing mesh properies
    const meshRef = React.useRef();

    // Action for interactive segments
    const actionProps = {
        nr: nr,
        onClick: (state) => {
            state.stopPropagation();
            if (url) window.open(url);
            clickHandler(state, meshRef);
        },
        onPointerOver: (state) => {
            state.stopPropagation();
            setHover(true);
            hoverStartHandler(state, meshRef);
            if (interactive || url) document.body.style.cursor = 'pointer';
        },
        onPointerOut: (state) => {
            state.stopPropagation();
            setHover(false);
            hoverEndHandler(state, meshRef);
            if (interactive || url) document.body.style.cursor = 'auto';
        }
    }

    // If depth is defined, overrule depth_a and depth_b with half of depth
    if (depth) {
        depth_a = 0.5 * depth;
        depth_b = 0.5 * depth;
    }

    // If only depth_a is defined, set depth_b to 0
    else if (depth_a && !depth_b) {
        depth_b = 0;
    }

    // If only depth_b is defined, set depth_a to 0
    else if (depth_b && !depth_a) {
        depth_a = 0;
    }

    // Use Effect to load SVG data only on render
    React.useEffect(() => {

        // Load SVG
        if (svgData && svgData.paths) {
            const segmentPaths = svgData.paths.filter(segmentPath => pathIds.includes(segmentPath.userData.node.id));

            // Turn each path into an extruded geometry
            segmentPaths.forEach(segmentPath => {
                const shapes = segmentPath.toShapes();
                const extrudeSettings = {
                    steps: 1,
                    depth: depth_a + depth_b,
                    bevelEnabled: false
                };
                const geometry = new THREE.ExtrudeGeometry(shapes, extrudeSettings);

                // Explicitely compute geometry's bounding box
                geometry.computeBoundingBox();
                geometry.computeBoundingSphere();
                const size = geometry.boundingBox.max.sub(geometry.boundingBox.min).toArray();
                const position = geometry.boundingSphere.center.toArray();

                // Create new mesh
                const newMesh = <group key={"react_element_" + segmentPath.userData.node.id}>
                    <mesh
                        ref={meshRef}
                        geometry={geometry}
                        scale={[1, -1, 1]}
                        position-z={-1 * depth_b + offset}
                        castShadow
                        receiveShadow
                        {...(!box && (interactive || url) ? actionProps : {})} >

                        {/* Material */}
                        <meshPhongMaterial
                            color={color ? color : segmentPath.userData.style.fill}
                            flatShading={true}
                            transparent={segment} />

                        {/* Make box of same size interactive if box */}
                        {box && (interactive || url) ? <mesh
                            position={position}
                            scale={boxScale}
                            visible={false}
                            castShadow={false}
                            receiveShadow={false}
                            {...actionProps} >
                            <boxGeometry args={size} />
                        </mesh> : null}
                    </mesh>
                </group>

                // Add mesh to meshList state
                setMeshList(meshList => [...meshList, newMesh]);
            })
        }

        // On destroy, empty mesh list
        return () => setMeshList([]);

        // eslint-disable-next-line
    }, [svgData])

    // Update segment properties
    useFrame(() => {

        // Interpolate position if posTo is set
        if (meshRef.current && meshRef.current.posTo) {
            const posTo = meshRef.current.posTo;
            meshRef.current.position.x = THREE.MathUtils.lerp(meshRef.current.position.x, posTo.pos.x, posTo.lerp);
            meshRef.current.position.y = THREE.MathUtils.lerp(meshRef.current.position.y, posTo.pos.y, posTo.lerp);
            meshRef.current.position.z = THREE.MathUtils.lerp(meshRef.current.position.z, posTo.pos.z, posTo.lerp);
        }

        // Interpolate scale if scaleTo is set 
        if (meshRef.current && meshRef.current.scaleTo) {
            const scaleTo = meshRef.current.scaleTo;
            meshRef.current.scale.x = THREE.MathUtils.lerp(meshRef.current.scale.x, scaleTo.scale.x, scaleTo.lerp);
            meshRef.current.scale.y = THREE.MathUtils.lerp(meshRef.current.scale.y, scaleTo.scale.y, scaleTo.lerp);
            meshRef.current.scale.z = THREE.MathUtils.lerp(meshRef.current.scale.z, scaleTo.scale.z, scaleTo.lerp);
        }

        // Otherwise, handle scaling and opacity for segments based on state
        else if (meshRef.current && segment) {

            const scaleBool = on || ((interactive || url) && hover);
            meshRef.current.castShadow = scaleBool;

            // Don't use lerp on mobile devices
            if (isMobile) {
                meshRef.current.scale.z = scaleBool ? 1 : 0.1;
                meshRef.current.material.opacity = scaleBool ? 1 : 0.1;
            }
            else {
                meshRef.current.scale.z = THREE.MathUtils.lerp(meshRef.current.scale.z, scaleBool ? 1 : 0.1, lerp);
                meshRef.current.material.opacity = THREE.MathUtils.lerp(meshRef.current.material.opacity, scaleBool ? 1 : 0.1, lerp);
            }
        }
    })    

    // Return a group containing all meshes
    return (
        <group {...props}>
            {meshList}
        </group>
    )
}

// Make PathMesh the default export
export default PathMesh;