// General imports
import * as React from "react";

// Clock component imports
import PathMesh from "./PathMesh";

// Bit character map
const bitChars = {0: 0xC3F, 1: 0x406, 2: 0xDB, 3: 0x8F, 4: 0xE6, 5: 0xED, 6: 0xFD, 7: 0x1401, 8: 0xFF, 9: 0xE7,
                  a: 0xF7, b: 0x128F, c: 0x39, d: 0x120F, e: 0xF9, f: 0xF1, g: 0xBD, h: 0xF6, i: 0x1209, j: 0x1E,
                  k: 0x2470, l: 0x38, m: 0x536, n: 0x2136, o: 0x3F, p: 0xF3, q: 0x203F, r: 0x20F3, s: 0x18D, t: 0x1201,
                  u: 0x3E, v: 0xC30, w: 0x2836, x: 0x2D00, y: 0x1500, z: 0xC09, space: 0x0, dash: 0xC0}

// React component that contains one fourteen segment character
const FourteenSegmentChar = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, character, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp
    }

    // State containing character
    const [bitChar, setBitChar] = React.useState(bitChars['space'])

    // Update when character changes
    React.useEffect(() => {
        const key = character == null ? 'space' : character.toLowerCase().replace(' ', 'space').replace('-', 'dash');
        setBitChar(key in bitChars ? bitChars[key] : bitChars['space']);
    }, [character])

    // Return canvas with components
    return (
        <group {...props} >
            <PathMesh {...pathMeshProps} pathIds={['14_segment_a']} segment={true} on={bitChar & (1 << 0)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_b']} segment={true} on={bitChar & (1 << 1)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_c']} segment={true} on={bitChar & (1 << 2)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_d']} segment={true} on={bitChar & (1 << 3)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_e']} segment={true} on={bitChar & (1 << 4)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_f']} segment={true} on={bitChar & (1 << 5)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_g1']} segment={true} on={bitChar & (1 << 6)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_g2']} segment={true} on={bitChar & (1 << 7)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_h']} segment={true} on={bitChar & (1 << 8)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_i']} segment={true} on={bitChar & (1 << 9)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_j']} segment={true} on={bitChar & (1 << 10)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_k']} segment={true} on={bitChar & (1 << 11)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_l']} segment={true} on={bitChar & (1 << 12)} />
            <PathMesh {...pathMeshProps} pathIds={['14_segment_m']} segment={true} on={bitChar & (1 << 13)} />
        </group>
    );
}

// Make FourteenSegmentChar the default export
export default FourteenSegmentChar;