// General imports
import * as React from "react";

// Clock component imports
import PathMesh from "./PathMesh";

// Default parameters
const offset_y = 0.179;

// React component that contains the progress indicator
const ProgressIndicator = ({ svgData, depth, depth_a, depth_b, offset, color, lerp, ...props }) => {

    // Genereal pathMesh props
    const pathMeshProps = {
        svgData: svgData,
        depth: depth,
        depth_a: depth_a,
        depth_b: depth_b,
        offset: offset,
        color: color,
        lerp: lerp
    }

    // Level state
    const [level, setLevel] = React.useState(0);

    // Respond to click event
    const clickHandler = (event) => {
        if (event.eventObject.nr) {
            setLevel(event.eventObject.nr);
        }
    }

    // Create an array of all bars
    let bars = [];
    for (let i = 1; i <= 20; i++) {
        bars.push(<PathMesh
            {...pathMeshProps}
            pathIds={['progress_bar']}
            key={'progress_bar_' + i.toString()}
            position-y={(i - 1) * offset_y}
            segment={true}
            interactive
            nr={i}
            on={i <= level}
            box
            boxScale={[1, 1.5, 1]}
            clickHandler={clickHandler} />
        )
    }

    // Return canvas with components
    return (
        <group {...props}>
            {bars}
        </group>
    );
}

// Make ProgressIndicator the default export
export default ProgressIndicator;